import { useTranslation } from "next-i18next";
import IconMyGoods from "../../../icons/icon-myGoods.svg";
import IconStock from "../../../icons/icon-stock.svg";
import IconLogin from "../../../icons/icon-login.svg";
import IconPhone from "../../../icons/icon-telephone.svg";
import * as S from "./styled";
import { useAppSelector } from "@app/store";
import { selectUser } from "@app/store/core/selectors";
import { hasData } from "../../../../libs/remote";

const FooterToolbar = () => {
  const user = useAppSelector(selectUser);

  return (
    <S.FooterToolbarWrap>
      <S.NavWrap>
        <S.NavLink href="/myGoods">
          <IconMyGoods />
          Мои товары
        </S.NavLink>
        {/*<S.NavLink href="/sales">*/}
        {/*    <IconStock />*/}
        {/*    Акции*/}
        {/*</S.NavLink>*/}
        {hasData(user) && user.data?.is_user_verified && (
          <S.NavLink href="/profile">
            <IconLogin />
            Профиль
          </S.NavLink>
        )}
        <S.NavLink href="/contacts">
          <IconPhone />
          Контакты
        </S.NavLink>
      </S.NavWrap>
    </S.FooterToolbarWrap>
  );
};

export default FooterToolbar;
