import { AxiosError } from "axios";
import {basePath, ProductsController} from "@app/api";
import { put, select, takeLatest } from "@redux-saga/core/effects";
import { all } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { failure, pending, RemoteDataStatus, success } from "../../../libs/remote";
import { ProductDetailDTO, ProductListResponseDTO, SearchProductListResponseDTO } from "@app/api/generated-api";
import {
  fetchDetailProduct,
  fetchProducts,
  fetchSearchProducts,
  postPleadData,
  postProduct,
  setDetailProduct,
  setPostPleadStatus,
  setPostProductStatus,
  setProducts,
  setSearchProduct,
} from "@app/store/myGoods/slice";
import {selectProducts} from "@app/store/myGoods/selectors";
import { getCookie } from "@app/utils/core-functions";

function* myGoodsSaga(): SagaIterator {
  yield all([
    /**
     * Получение списка товаров
     */
    takeLatest(fetchProducts, function* fetchProductsSaga({ payload }) {
      try {
        const { data }: { data: ProductListResponseDTO } = yield ProductsController.getProductList(
          payload.page_size ?? 12,
          payload.page_number ?? 1,
        );
        if (payload.showMore) {
          const oldProducts: { data: ProductListResponseDTO } = yield select(selectProducts);
          if (oldProducts.data.data) {
            yield put(setProducts(pending()));
            yield put(
              setProducts(
                success({
                  total: data.total,
                  count: data.count + oldProducts.data.count,
                  data: {
                    warranty_product_list: [
                      ...oldProducts.data.data.warranty_product_list,
                      ...data.data.warranty_product_list,
                    ],
                    content_product_list: [
                      ...oldProducts.data.data.content_product_list,
                      ...data.data.content_product_list,
                    ],
                  },
                }),
              ),
            );
          }
        } else {
          yield put(setProducts(pending()));
          yield put(setProducts(success(data)));
        }
      } catch (err) {
        yield put(setProducts(failure((err as AxiosError).message)));
      }
    }),

    /**
     * Получение детального товара
     */
    takeLatest(fetchDetailProduct, function* fetchDetailProductSaga({ payload }) {
      try {
        yield put(setDetailProduct(pending()));
        const { data }: { data: ProductDetailDTO } = yield ProductsController.getProductDetail(payload.id);
        yield put(setDetailProduct(success(data)));
      } catch (err) {
        yield put(setDetailProduct(failure((err as AxiosError).message)));
      }
    }),

    /**
     * Получение списка поисковых товара
     */
    takeLatest(fetchSearchProducts, function* fetchSearchProductsSaga({ payload }) {
      try {
        yield put(setSearchProduct(pending()));
        const { data }: { data: SearchProductListResponseDTO } = yield ProductsController.getSearchProductList(
          payload.q,
          3,
          1,
        );
        yield put(setSearchProduct(success(data)));
      } catch (err) {
        yield put(setDetailProduct(failure((err as AxiosError).message)));
      }
    }),

    /**
     * Добавление товара
     */
    takeLatest(postProduct, function* postProductSaga({ payload }) {
      try {
        yield put(setPostProductStatus(RemoteDataStatus.Pending));
        const userToken = getCookie("access_token");
        let data: any;
        yield fetch(`${basePath}/api/v1/products`, {
          method: "post",
          body: payload.data,
          headers: {
            Authorization: `Bearer ${userToken}` || "",
          },
        }).then((res) => (data = res));
        yield put(setPostProductStatus(RemoteDataStatus.Success));
      } catch (err) {
        yield put(setPostProductStatus(RemoteDataStatus.Failure));
      }
    }),

    /**
     * Обращение в сервисный центр
     */
    takeLatest(postPleadData, function* postPleadSaga({ payload }) {
      try {
        yield put(setPostPleadStatus(RemoteDataStatus.Pending));
        const userToken = getCookie("access_token");
        let data: any;
        yield fetch(`${basePath}/api/v1/claims`, {
          method: "post",
          body: payload.data,
          headers: {
            Authorization: `Bearer ${userToken}` || "",
          },
        }).then((res) => (data = res));
        yield put(setPostPleadStatus(RemoteDataStatus.Success));
      } catch (err) {
        yield put(setPostPleadStatus(RemoteDataStatus.Failure));
      }
    }),
  ]);
}

export default myGoodsSaga;
