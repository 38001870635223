import {AxiosError, AxiosResponse} from "axios";
import {AuthController, basePath, ProductsController, UserController} from "@app/api";
import {deleteCookie, getCookie} from "@app/utils/core-functions";
import {put, takeLatest} from "@redux-saga/core/effects";
import {all} from "redux-saga/effects";
import {SagaIterator} from "redux-saga";
import {failure, initialized, pending, RemoteDataStatus, success} from "../../../libs/remote";
import {
    confirmUser,
    fetchUser,
    postRegistrationForm,
    refreshAuth,
    resetConfirmation,
    resetConfirmationStatus,
    setConfirmStatus,
    setPostRegistrationFormStatus,
    setUser,
    signIn,
    signOut,
    signStatus
} from "@app/store/core/slice";
import {ConfirmationLoginResponseDTO, TokenResponseDTO, UserResponseDTO} from "@app/api/generated-api";

function* coreSaga(): SagaIterator {
    yield all([
        /**
         * Авторизировать пользователя
         */
        takeLatest(signIn, function* signInSaga({ payload }) {
            try {
                yield put(signStatus(pending()));
                const { data }: { data: ConfirmationLoginResponseDTO } = yield AuthController.loginByPhone(payload);
                yield put(signStatus(success(data)));
            } catch (err) {
                yield put(signStatus(failure((err as AxiosError).message)));
            }
        }),

        /**
         * Отправить повторно код авторизации
         */
        takeLatest(resetConfirmation, function* resetConfirmationSaga({ payload }) {
            try {
                yield put(resetConfirmationStatus(RemoteDataStatus.Pending));
                yield AuthController.resendConfirmationCode(payload);
                yield put(resetConfirmationStatus(RemoteDataStatus.Success));
            } catch (err) {
                yield put(signStatus(failure((err as AxiosError).message)));
            }
        }),

        /**
         * Получение информации о пользователе
         */
        takeLatest(fetchUser, function* setUserSaga() {
            try {
                yield put(setUser(pending()));
                const { data }: { data: UserResponseDTO } = yield UserController.getUserInfo();
                yield put(setUser(success(data)));
            } catch (err) {
                yield put(setUser(failure((err as AxiosError).message)));
            }
        }),

        /**
         * Разлогинить пользователя
         */
        takeLatest(signOut, function* signOutSaga() {
            try {
                yield AuthController.makeLogout();
                deleteCookie("access_token");
                deleteCookie("refresh");
                yield window.location.reload();
                yield window.location.replace("/");
            } catch (err) {
                console.error(err);
            }
        }),

        /**
         * Подтверждение номера телефона
         */
        takeLatest(confirmUser, function* confirmUserSaga({payload}) {
            try {
                yield put(setConfirmStatus(RemoteDataStatus.Pending));
                const { data }: { data: TokenResponseDTO } = yield AuthController.confirmationLoginByPhone(payload);

                const { access_token, expired_in } = data;

                if (expired_in) {
                    document.cookie = `access_token=${access_token}; max-age=${expired_in}`;
                    document.cookie = `refresh=true; max-age=${expired_in - 3600}`;
                }
                yield put(setConfirmStatus(RemoteDataStatus.Success));
            } catch (err) {
                yield put(setConfirmStatus(RemoteDataStatus.Failure));
            }
        }),

        /**
         * Обновление access_token
         */
        takeLatest(refreshAuth, function* checkAuthSaga() {
            try {
                const res: AxiosResponse<TokenResponseDTO> = yield AuthController.makeRefresh();
                if (res.status !== 200) throw new Error(`${res.status}`);
                const { access_token, expired_in } = res.data;
                deleteCookie("access_token");
                deleteCookie("refresh");
                if (expired_in) {
                    document.cookie = `access_token=${access_token}; max-age=${expired_in}`;
                    document.cookie = `refresh=true; max-age=${expired_in - 3600}`;
                }
            } catch (err) {
                yield put(signOut());
                console.error(err);
            }
        }),

        /**
         * Разлогинить пользователя
         */
        takeLatest(postRegistrationForm, function* postRegistrationFormSaga({payload}) {
            try {
                const userToken = getCookie("access_token");
                yield put(setPostRegistrationFormStatus(RemoteDataStatus.Pending));
                yield UserController.patchUserInfo(payload.data.userData);
                let data: any;
                yield fetch(`${basePath}/api/v1/products`, {
                    method: "post",
                    body: payload.data.productData,
                    headers: {
                        Authorization: `Bearer ${userToken}` || "",
                    },
                }).then((res) => (data = res));
                yield put(setPostRegistrationFormStatus(RemoteDataStatus.Success));
            } catch (err) {
                yield put(setPostRegistrationFormStatus(RemoteDataStatus.Failure));
            }
        }),
    ]);
}

export default coreSaga;