import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalIdEnum, ModalState } from "./types";
import {
    AuthCredentialsRequestDTO,
    ConfirmationAuthCredentialsRequestDTO,
    ConfirmationLoginResponseDTO, PatchUserRequest,
    ResendCodeRequestDTO,
    TokenResponseDTO,
    UserResponseDTO
} from "../../api/generated-api";
import { initialized, RemoteData, RemoteDataStatus } from "../../../libs/remote";

export interface ICoreState {
    user: RemoteData<UserResponseDTO> | null;
    modals: ModalState;
    auth: RemoteData<ConfirmationLoginResponseDTO>;
    confirmStatus: RemoteDataStatus | null;
    resetConfirmStatus: RemoteDataStatus | null;
    postRegistrationFormStatus: RemoteDataStatus;
    isPolicyVisible: boolean
}

const initialState: ICoreState = {
    user: initialized() as RemoteData<UserResponseDTO>,
    modals: initialized() as ModalState,
    auth: initialized() as RemoteData<ConfirmationLoginResponseDTO>,
    confirmStatus: null,
    resetConfirmStatus: null,
    postRegistrationFormStatus: RemoteDataStatus.Initialized,
    isPolicyVisible: false
};

export const coreSlice = createSlice({
    name: "core",
    initialState,
    reducers: {
        signIn: (_state, _payload: PayloadAction<AuthCredentialsRequestDTO>) => {},
        signStatus: (state, action: PayloadAction<RemoteData<ConfirmationLoginResponseDTO>>) => {
            state.auth = action.payload;
        },

        confirmUser: (_state, _payload: PayloadAction<ConfirmationAuthCredentialsRequestDTO>) => {},
        setConfirmStatus: (state, action: PayloadAction<RemoteDataStatus>) => {
            state.confirmStatus = action.payload;
        },

        resetConfirmation: (_state, _payload: PayloadAction<ResendCodeRequestDTO>) => {},
        resetConfirmationStatus: (state, action: PayloadAction<RemoteDataStatus>) => {
            state.resetConfirmStatus = action.payload;
        },

        /**
         * Отправка формы
         */
        postRegistrationForm: (_state, _action: PayloadAction<{data: {userData: PatchUserRequest, productData: FormData} }>)  => {},
        setPostRegistrationFormStatus: (state, action: PayloadAction<RemoteDataStatus>) => {
            state.postRegistrationFormStatus = action.payload;
        },

        refreshAuth: (_state) => {},

        setUser: (state, action: PayloadAction<RemoteData<UserResponseDTO>>) => {
            state.user = action.payload;
        },
        fetchUser: (_state) => {},

        signOut: () => {},


        changePolicyModalState: (
            state, {payload}: PayloadAction<{
                visibility?: boolean;
            }>
        ) => {
            state.isPolicyVisible = payload.visibility},


        changeModalState: (
            state,
            {
                payload,
            }: PayloadAction<{
                type: ModalIdEnum;
                visibility?: boolean;
                data?: Record<string, any>;
                loading?: boolean;
            }>,
        ) => {
            state.modals[payload.type] = {
                ...state.modals[payload.type],
                type: payload.type,
                visibility: typeof payload.visibility !== "undefined" ? payload.visibility : true,
                data: {
                    ...(payload.data ? { ...state.modals[payload.type]?.data, ...payload.data } : {}),
                },
                loading: payload.loading,
            };
        },
    },
});

export const {
    signIn,
    setUser,
    signOut,
    changeModalState,
    fetchUser,
    refreshAuth,
    signStatus,
    confirmUser,
    setConfirmStatus,
    resetConfirmation,
    resetConfirmationStatus,
    postRegistrationForm,
    setPostRegistrationFormStatus,
    changePolicyModalState
} = coreSlice.actions;
export default coreSlice.reducer;
