import { notification } from "antd";
import Cookies from 'cookie';

export function getCookie(name: string) {
    if (process.browser) {
        let matches = document.cookie.match(
            new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"),
        );
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
}

export function setCookie(name: string, value: string, options: any = {}) {
    if (process.browser) {

    } options = {
        // при необходимости добавьте другие значения по умолчанию
        ...options,
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export function deleteCookie(name: string) {
    if (process.browser) {
        setCookie(name, "", {
            "max-age": -1,
        });
    }
}

export const debounce = (fn: Function, ms: number) => {
    let timeout: NodeJS.Timeout;
    return function () {
        const fnCall = () => {
            // @ts-ignore
            fn.apply(this, arguments);
        };
        clearTimeout(timeout);
        timeout = setTimeout(fnCall, ms);
    };
};

export const openErrorNotification = () => {
    notification.error({
        message: "Что-то пошло не так!",
        description: "Кажется, ошибка с нашей стороны. Попробуйте позднее еще раз или свяжитесь с поддержкой.",
    });
};
export const isEscEvent = (evt: KeyboardEvent) => evt.key === "Escape" || evt.key === "Esc";
