import {RootState} from "@app/store";
import {RemoteData, RemoteDataStatus} from "../../../libs/remote";
import {ConfirmationLoginResponseDTO, UserResponseDTO} from "@app/api/generated-api";


export const selectSignIn = (state: RootState): RemoteData<ConfirmationLoginResponseDTO> => state.core.auth;

export const selectConfirmStatus = (state: RootState): RemoteDataStatus => state.core.confirmStatus;

export const selectResetConfirmStatus = (state: RootState): RemoteDataStatus => state.core.resetConfirmStatus;

export const selectUser = (state: RootState): RemoteData<UserResponseDTO> => state.core.user;

export const selectPostRegistrationFormStatus = (state: RootState): RemoteDataStatus => state.core.postRegistrationFormStatus;

export const selectPolicyVisible = (state: RootState): boolean => state.core.isPolicyVisible;
