import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatchUserRequest, UserAddressDTO } from "@app/api/generated-api";
import {RemoteDataStatus} from "../../../libs/remote";

interface IUserName {
    firstName?: string;
    secondName?: string;
    lastName?: string;
}

export type UserDataType = {
    userName: {
        value: IUserName | null,
        error: boolean,
    },
    birthday: {
        value: string | null,
        error: boolean,
    },
    phone: {
        value: string | null,
        error: boolean,
    },
    additionalPhone: number[]
    email: {
        value: string | null,
        error: boolean,
    },
    address: {
        value: UserAddressDTO | null,
        error: boolean,
    }
}

export interface IUserProfileState {
    userPatchData: UserDataType,
    patchUserDataStatus: RemoteDataStatus
}

const initialState: IUserProfileState = {
    userPatchData: {
        userName: {
            value: null,
            error: false,
        },
        birthday: {
            value: null,
            error: false,
        },
        phone: {
            value: null,
            error: false,
        },
        additionalPhone: [],
        email: {
            value: null,
            error: false,
        },
        address: {
            value: null,
            error: false,
        }
    },
    patchUserDataStatus: RemoteDataStatus.Initialized
};

export const userProfileSlice = createSlice({
    name: "userProfile",
    initialState,
    reducers: {
        postUserData: (_state, _payload: PayloadAction<PatchUserRequest>) => {},

        patchUserDataStatus: (state, action: PayloadAction<RemoteDataStatus>) => {
            state.patchUserDataStatus = action.payload;
        },

        setUserName: (state, action: PayloadAction<IUserName | null>) => {
            state.userPatchData.userName.value = action.payload;
        },

        setErrorUserName: (state, action: PayloadAction<boolean>) => {
            state.userPatchData.userName.error = action.payload;
        },

        setBirthday: (state, action: PayloadAction<string | null>) => {
            state.userPatchData.birthday.value = action.payload;
        },

        setErrorBirthday: (state, action: PayloadAction<boolean>) => {
            state.userPatchData.birthday.error = action.payload;
        },
        setPhone: (state, action: PayloadAction<string | null>) => {
            state.userPatchData.phone.value = action.payload;
        },
        setErrorPhone: (state, action: PayloadAction<boolean>) => {
            state.userPatchData.phone.error = action.payload;
        },
        setAdditionalPhone: (state, action: PayloadAction<number[]>) => {
            state.userPatchData.additionalPhone = action.payload;
        },
        setEmail: (state, action: PayloadAction<string | null>) => {
            state.userPatchData.email.value = action.payload;
        },
        setErrorEmail: (state, action: PayloadAction<boolean>) => {
            state.userPatchData.email.error = action.payload;
        },
        setAddress: (state, action: PayloadAction<UserAddressDTO | null>) => {
            state.userPatchData.address.value = action.payload;
        },
        setErrorAddress: (state, action: PayloadAction<boolean>) => {
            state.userPatchData.address.error = action.payload;
        },
    },
});

export const {
    postUserData,
    patchUserDataStatus,
    setUserName,
    setErrorUserName,
    setErrorBirthday,
    setErrorPhone,
    setErrorEmail,
    setErrorAddress,
    setBirthday,
    setPhone,
    setEmail,
    setAddress,
    setAdditionalPhone
} = userProfileSlice.actions;
export default userProfileSlice.reducer;
