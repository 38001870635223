import {theme, ThemeProvider, GlobalStyle} from "../styled-components";
import { Provider } from "react-redux";
import {store, useAppSelector} from "../store";
import Layout from "@app/containers/layout";
import { appWithTranslation } from "next-i18next";
import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import "dayjs/locale/ru";
import 'swiper/css';
import "../global.css";

const WrappedApp = ({Component, pageProps}) => {

    const reqSvg = require.context("src/icons", false, /\.svg$/);
    reqSvg.keys().reduce((images: { [key in string]: any }, path) => {
        images[path] = reqSvg(path);
        return images;
    }, {});

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ConfigProvider locale={ruRU}>
                    <GlobalStyle />
                    <Layout>
                        <Component {...pageProps}/>
                    </Layout>
                </ConfigProvider>
            </ThemeProvider>
        </Provider>
    )
}

export default appWithTranslation(WrappedApp)
