import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PagerType } from "./types";
import { ProductDetailDTO, ProductListResponseDTO, SearchProductListResponseDTO } from "../../api/generated-api";
import { initialized, RemoteData, RemoteDataStatus } from "../../../libs/remote";

export interface IMyGoodsState {
  products: RemoteData<ProductListResponseDTO>;
  detailProduct: RemoteData<ProductDetailDTO>;
  pager: PagerType;
  searchProduct: RemoteData<SearchProductListResponseDTO>;
  postProductStatus: RemoteDataStatus;
  postPleadStatus: RemoteDataStatus;
}

const initialState: IMyGoodsState = {
  products: initialized() as RemoteData<ProductListResponseDTO>,
  detailProduct: initialized() as RemoteData<ProductDetailDTO>,
  pager: { page_size: null, page_number: null, showMore: false },
  searchProduct: initialized() as RemoteData<SearchProductListResponseDTO>,
  postProductStatus: RemoteDataStatus.Initialized,
  postPleadStatus: RemoteDataStatus.Initialized,
};

export const myGoodsSlice = createSlice({
  name: "myGoods",
  initialState,
  reducers: {
    fetchProducts: (_state, _payload: PayloadAction<PagerType>) => {},
    setProducts: (state, action: PayloadAction<RemoteData<ProductListResponseDTO>>) => {
      state.products = action.payload;
    },

    fetchDetailProduct: (_state, _payload: PayloadAction<{ id: string }>) => {},
    setDetailProduct: (state, action: PayloadAction<RemoteData<ProductDetailDTO>>) => {
      state.detailProduct = action.payload;
    },

    fetchSearchProducts: (_state, _payload: PayloadAction<{ q: string }>) => {},
    setSearchProduct: (state, action: PayloadAction<RemoteData<SearchProductListResponseDTO>>) => {
      state.searchProduct = action.payload;
    },

    postProduct: (_state, _payload: PayloadAction<{ data: FormData }>) => {},
    setPostProductStatus: (state, action: PayloadAction<RemoteDataStatus>) => {
      state.postProductStatus = action.payload;
    },

    setPager: (state, action: PayloadAction<PagerType>) => {
      state.pager = action.payload;
    },
    postPleadData: (_state, _payload: PayloadAction<{ data: FormData }>) => {},
    setPostPleadStatus: (state, action: PayloadAction<RemoteDataStatus>) => {
      state.postPleadStatus = action.payload;
    },
  },
});

export const {
  fetchProducts,
  setProducts,
  setPager,
  fetchDetailProduct,
  setDetailProduct,
  fetchSearchProducts,
  setSearchProduct,
  postProduct,
  setPostProductStatus,
  postPleadData,
  setPostPleadStatus,
} = myGoodsSlice.actions;
export default myGoodsSlice.reducer;
