import { RootState } from "@app/store";
import { RemoteData, RemoteDataStatus } from "../../../libs/remote";
import {
  ConfirmationLoginResponseDTO,
  ProductDetailDTO,
  ProductListResponseDTO,
  SearchProductListResponseDTO,
} from "@app/api/generated-api";
import { PagerType } from "@app/store/myGoods/types";

export const selectProducts = (state: RootState): RemoteData<ProductListResponseDTO> | null => state.products.products;

export const selectDetailProduct = (state: RootState): RemoteData<ProductDetailDTO> | null =>
  state.products.detailProduct;

export const selectSearchProduct = (state: RootState): RemoteData<SearchProductListResponseDTO> | null =>
  state.products.searchProduct;

export const selectPostProductStatus = (state: RootState): RemoteDataStatus => state.products.postProductStatus;

export const selectPager = (state: RootState): PagerType => state.products.pager;

export const selectPleadResponse = (state: RootState): RemoteDataStatus => state.products.postPleadStatus;
