import React from "react";
import Logo from '../../../../icons/icon-logo.svg';
import LogoDamixa from '../../../../icons/icon-header-damixa.svg';
import LogoDorff from '../../../../icons/icon-header-dorff.svg';
import * as S from './styled';

const LogoSlider = () => {
    const settings = {
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
    };

    return (
        <div>
            <S.SliderWrap {...settings}>
                <S.HeaderLogo href="/">
                    <Logo />
                </S.HeaderLogo>
                <S.HeaderLogo href="/">
                    <LogoDamixa />
                </S.HeaderLogo>
                <S.HeaderLogo href="/">
                    <LogoDorff />
                </S.HeaderLogo>
            </S.SliderWrap>
        </div>
    );
}

export default LogoSlider;

